<template>
  <div class="page_container">
    <div class="article">
      <div class="title">{{ article.title }}</div>
      <div class="tools">
        <div class="author_info">
          <!-- 新增处理内容图片 -->
          <div class="author">
            <img v-if="article.iconUrl" :src="article.iconUrl"/>
            <img v-else src="@/assets/image/authorImg.png">
            <span>{{ article.authorName }}</span>
          </div>
          <span class="time">{{ article.relaseTime }}</span>
        </div>
        <div>
          <span><i class="fa fa-eye"></i>{{ article.scanCnt }}</span>
          <span><i class="fa fa-thumbs-o-up"></i>{{ isPraise ? (article.praiseCnt + 1) : article.praiseCnt }}</span>
          <span style="position: relative;margin-left:30px" class="share" @mouseout="leaveImg"
                @mouseover="intoImg(article)">
                <i class="fa fa-share-alt"></i>{{article.shareCnt}}
                <div class="share_img">
                  <div :ref="'qr'+ article.atUuid"></div>
                </div>
              </span>
        </div>
      </div>
      <p class="content" v-html="article.context"></p>
      <div class="source" v-if="article.source">来源于：{{ article.source }}</div>
      <!-- 点赞 -->
      <div style="height: 100px;display: flex;justify-content: center">
        <vue-star-plus v-model="isPraise" style="margin: 0 auto">
          <i slot="icon" :style="{
          color: (isPraise ? '#fff' : '#fff'),
          padding: ('8px 16px'),
          borderRadius: ('45px'),
          cursor: 'pointer',
          userSelect: 'none',
          background: (isPraise ? '#3e35e7' : '#c9c9c9')
        }" class="fa fa-thumbs-up" @click="praise"><span
              style="font-size: 0.8rem;margin-left: 3px;position: relative;top: -1.5px">{{
              isPraise ? '已点赞' : '点赞'
            }}</span></i>
        </vue-star-plus>
      </div>
    </div>

    <div class="aside_area" ref="fastDetailDom">
      <div>
        <hot-article ref="hotArticle"></hot-article>
      </div>
    </div>


  </div>
</template>

<script>
import HotArticle from "../../components/HotArticle/HotArticle";
import QRCode from "qrcodejs2";

export default {
  name: "index",
  components: {HotArticle},
  data: () => ({
    article: {
      relaseTime: '',
      title: '',
      authorName: '',
      scanCnt: '',
      praiseCnt: '',
      context: '',
      labelList: [],
      source: '',
      artNums: '',
    },
    isPraise: false,
    clientHeight: undefined,

    codeBaseUrl: "https://www.metaversehub.net/nft-api/h5/news/",
    imgTimer: null
  }),
  async beforeRouteUpdate(to, from, next) {
     this.getDetail(to.params.uuid);
    next()
  },
  async mounted() {
    this.clientHeight = document.documentElement.clientHeight
    let uuid = this.$route.params.uuid
    await this.getDetail(uuid)
    window.addEventListener('scroll', this.fixedAside, true)
    this.createImg(this.article);
    this.$nextTick(() => {
      setTimeout(() => {
        this.fixedAside()
      }, 0)
    })
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.fixedAside, true);
  },
  methods: {
    intoImg(obj) {
      this.imgTimer = setTimeout(() => {
        this.addTime(obj);
      }, 200)
    },
    leaveImg() {
      clearInterval(this.imgTimer)
    },
    addTime(obj) {
      this.axios.post('/article/share', {uuid: obj.atUuid}).then(res => {
        console.log('res', res)
      })
    },
    createImg(obj) {
      let _this = this;
      new QRCode(this.$refs['qr' + obj.atUuid], {
        text: _this.codeBaseUrl + obj.atUuid, // 需要转换为二维码的内容
        width: 110,
        height: 110,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    fixedAside() {
      if (document.documentElement.scrollHeight == document.documentElement.clientHeight) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.fastDetailDom.style.height = this.clientHeight - 362 + 'px'
          }, 0)
        })
        return
      }
      let scrollBottom =
          document.documentElement.scrollHeight -
          document.documentElement.scrollTop -
          document.documentElement.clientHeight;
      if (scrollBottom <= 281 && scrollBottom >= 200) {
        this.$refs.fastDetailDom.style.height = 'calc(100vh - 100px - 60px)'
      } else if (scrollBottom < 200 && scrollBottom >= 100) {
        this.$refs.fastDetailDom.style.height = 'calc(100vh - 100px - 160px)'
      } else if (scrollBottom < 100 && scrollBottom >= 50) {
        this.$refs.fastDetailDom.style.height = 'calc(100vh - 100px - 190px)'
      } else if (scrollBottom < 50) {
        this.$refs.fastDetailDom.style.height = 'calc(100vh - 100px - 263px)'
      } else {
        this.$refs.fastDetailDom.style.height = 'calc(100vh - 100px)'
      }
      if (document.documentElement.scrollTop == 0 && scrollBottom > 281) {
        this.$refs.fastDetailDom.style.height = 'calc(100vh - 100px)'
      }


    },
    getDetail(uuid) {
      document.documentElement.scrollTop = 0
      return this.axios.get('/article/detail/' + uuid).then(res => {
        this.article = res.data
        this.article.uuid = uuid
      })
    },
    // 点赞
    praise() {
      let params = {
        uuid: this.article.uuid
      }
      if (this.isPraise) return
      else {
        this.axios.get('/article/praise', {params}).then(res => {
          console.log(res)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page_container {
  display: flex;
  margin-bottom: 40px;

  .article {
    width: 787px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 20px 40px;
    overflow: hidden;
    max-width: 100%;
   

    .title {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #333333;
      margin: 25px 0;
    }

    .tools {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-bottom: 10px;
      color: #666666;
      border-bottom: 1px solid #999999;

      .author_info {
        display: flex;
        align-items: center;

        .author {
          display: flex;
          margin-right: 20px;
          color: #999999;
          font-size: 14px;

          img {
            width: 22px;
            height: 22px;
            object-fit: cover;
          }
        }

        .time {
          font-size: 14px;
          color: #999999;
        }
      }


      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px;
      }

      > span {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 34px;
        color: #000000;
        margin-right: 30px;
        opacity: 1;
      }

      .fa {
        margin-right: 10px;
      }

      .fa-thumbs-o-up {
        margin-left: 30px;
      }
      .share {
        position: relative;

        &:hover .share_img {
          visibility: visible;
          transition: visibility 1s ease;
        }

        .share_img {
          visibility: hidden;
          cursor: pointer;
          box-sizing: border-box;
          padding: 6px;
          position: absolute;

          top: -60px;
          background-color: #FFFFFF;
          margin: 0;
          width: 120px;
          height: 120px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
          overflow: hidden
        }
      }
    }

    .content {
      width: 100%;
      max-width: 100%;
      font-size: 0.9rem;


      /deep/ h1, /deep/ h2, /deep/ h3, /deep/ h4, /deep/ h5, /deep/ h6 {
        margin: 10px 0;
        box-sizing: border-box;
      }
    }

    .source {
      margin-top: 40px;
      font-size: 0.9rem;
    }

  }


  .aside-area-bg {
    width: 283px;
  }

  .aside_area {
    position: fixed;
    margin-left: 787px;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 283px;
    transition: height 0.1s ease-in-out;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 20px;
    }

  }
}
</style>
